<template>
  <div class="profile">
    <a-page-header title="Profile Setting" />
    <div class="page-content">
      <a-row :gutter="25">
        <a-col
          :xl="8"
          :lg="10"
          :xs="24"
        >
          <a-card>
            <div class="profile-head">
              <div class="profile-avatar">
                <Avatar
                  class="avatar"
                  :username="username"
                  :size="120"
                  :inline="true"
                ></Avatar>
              </div>
              <div class="profile-username">{{ username }}</div>
              <div class="profile-role">{{ role }}</div>
            </div>
            <div
              v-if="windowWidth > 575"
              class="profile-tabs"
            >
              <a
                :class="[activetab === 'profile' ? 'active' : '']"
                @click="activetab = 'profile'"
              >
                <FeatherIcon type="user" />
                Edit Profile
              </a>
              <a
                :class="[activetab === 'password' ? 'active' : '']"
                @click="activetab = 'password'"
              >
                <FeatherIcon type="lock" />
                Change Password
              </a>
              <a
                :class="[activetab === 'notification' ? 'active' : '']"
                @click="activetab = 'notification'"
              >
                <FeatherIcon type="bell" />
                Notification Settings
              </a>
            </div>
            <a-dropdown
              v-else
              :trigger="['click']"
              :get-popup-container="(trigger) => trigger.parentNode"
            >
              <a
                class="ant-dropdown-link profile-dropdown"
                @click.stop=""
              >
                <div class="profile-dropdown-chosen">
                  <FeatherIcon :type="dropdownIcon" />
                  {{ dropdownText }}
                </div>
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    @click="
                      activetab = 'profile';
                      onChangeDropdown();
                    "
                  >
                    <FeatherIcon type="user" />
                    Edit Profile
                  </a-menu-item>
                  <a-menu-item
                    @click="
                      activetab = 'password';
                      onChangeDropdown();
                    "
                  >
                    <FeatherIcon type="lock" />
                    Change Password
                  </a-menu-item>
                  <a-menu-item
                    @click="
                      activetab = 'notification';
                      onChangeDropdown();
                    "
                  >
                    <FeatherIcon type="bell" />
                    Notification Settings
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-card>
        </a-col>
        <a-col
          :xl="16"
          :lg="14"
          :xs="24"
        >
          <div class="profile-editor">
            <div
              v-if="activetab === 'profile'"
              class="tabcontent"
            >
              <EditProfile
                :user-name="username"
                :account-id="accountId"
                :user-id="userId"
              />
            </div>
            <div
              v-if="activetab === 'password'"
              class="tabcontent"
            >
              <ChangePassword />
            </div>
            <div
              v-if="activetab === 'notification'"
              class="tabcontent"
            >
              <NotificationSettings
                :account-id="accountId"
                :user-id="userId"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import Avatar from '@/components/Avatar.vue';
import EditProfile from './EditProfile.vue';
import ChangePassword from './ChangePassword.vue';
import NotificationSettings from './NotificationSettings.vue';
import { DownOutlined } from '@ant-design/icons-vue';

export default {
  name: 'Profile',
  components: {
    Avatar,
    EditProfile,
    ChangePassword,
    NotificationSettings,
    DownOutlined,
  },
  setup() {
    let activetab = ref('profile');
    let dropdownText = ref('Edit Profile');
    let dropdownIcon = ref('user');
    const store = useStore();
    const route = useRoute();
    const brand = computed(() => store.state.account.brand);
    const username = computed(() => store.getters['account/userName']);
    const role = computed(() => store.getters['account/userRole']);
    const accountId = computed(() => store.getters['account/accountId']);
    const userId = computed(() => store.getters['account/userId']);

    const onChangeDropdown = () => {
      if (activetab.value == 'profile') {
        dropdownText.value = 'Edit Profile';
        dropdownIcon.value = 'user';
      } else if (activetab.value == 'password') {
        dropdownText.value = 'Change Password';
        dropdownIcon.value = 'lock';
      } else if (activetab.value == 'notification') {
        dropdownText.value = 'Notification Settings';
        dropdownIcon.value = 'bell';
      }
    };

    let windowWidth = ref(window.innerWidth);
    const onWidthChange = () => (windowWidth.value = window.innerWidth);

    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
    onMounted(() => {
      window.addEventListener('resize', onWidthChange);
      if (route.params.tab) {
        activetab.value = route.params.tab;
      }
    });
    watch(
      () => route.params.tab,
      () => {
        console.log(route);
        activetab.value = route.params.tab;
      }
    );

    return {
      activetab,
      dropdownText,
      dropdownIcon,
      brand,
      username,
      role,
      accountId,
      userId,
      windowWidth,
      onChangeDropdown,
    };
  },
};
</script>

<style lang="scss">
.profile {
  .page-content {
    padding: 0px 30px 20px;
    .ant-col {
      @media (max-width: 575px) {
        width: 100%;
      }
      .profile-head {
        text-align: center;
        @media (max-width: 575px) {
          display: none;
        }
        &::after {
          content: '';
          border-bottom: 1px solid #f1f2f6;
          width: calc(100% + 50px);
          display: inline-block;
          position: relative;
          left: -25px;
        }
        .profile-username {
          margin-top: 20px;
          font-size: 18px;
          line-height: 23px;
        }
        .profile-role {
          font-size: 14px;
          line-height: 18px;
          color: #9299b8;
        }
      }
      .profile-tabs {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        @media (max-width: 575px) {
          margin: 0;
        }
        a {
          color: #9299b8;
          border-radius: 5px;
          padding: 14px 20px;
          &.active {
            background: rgba(9, 124, 255, 0.05);
            color: #097cff;
          }
          i {
            margin-right: 20px;
          }
        }
      }
      .profile-dropdown {
        display: flex;
        justify-content: space-between;
        color: #5a5f7d;
        .profile-dropdown-chosen {
          display: flex;
          i {
            align-self: center;
            margin-right: 12px;
          }
        }
        .anticon-down {
          align-self: center;
        }
      }
      .profile-editor {
        .ant-card-head-title {
          .card-subtitle {
            font-size: 14px;
            font-weight: 400;
            color: #9299b8;
          }
        }
        .editor-container {
          max-width: 370px;
          margin: auto;
          .editor-head {
            font-size: 13px;
            line-height: 17px;
            color: #272b41;
            margin-top: 24px;
            font-weight: 500;
            &:first-child {
              margin-top: 0;
            }
          }
          .invalid-notice {
            font-size: 12px;
            margin-top: 5px;
            color: #ff4d4f;
          }
          .ant-input-password {
            padding-top: 0;
            padding-bottom: 0;
          }
          .ant-input {
            padding-top: 14px;
            padding-bottom: 10px;
          }
          .pass-criteria {
            margin-top: 10px;
            p {
              font-size: 12px;
              line-height: 15px;
              color: #9299b8;
              i {
                margin-right: 5px;
              }
              &.active {
                color: #ff4d4f;
              }
              &.active.valid {
                color: #20c997;
                i svg {
                  fill: #20c997;
                }
              }
            }
          }
          button {
            margin: 40px 0;
            @media (max-width: 575px) {
              width: 100%;
            }
          }
        }
        .editor-container-wide {
          .editor-head {
            font-size: 13px;
            line-height: 17px;
            color: #272b41;
            margin-top: 24px;
            font-weight: 500;
            display: inline-block;
            &:first-child {
              margin-top: 0;
            }
            i {
              vertical-align: middle;
              margin-left: 5px;
            }
          }
          .float-right {
            float: right;
            cursor: pointer;
            color: #097cff;
          }
          .group-toggle {
            border: 1px solid #f1f2f6;
            border-radius: 10px;
            margin-bottom: 30px;
            div {
              padding: 16px;
              border-bottom: 1px solid #f1f2f6;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              &:last-child {
                border: none;
              }
              p {
                margin: 0;
              }
              button,
              label {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    @media (max-width: 575px) {
      .ant-card,
      .profile-editor {
        border-radius: 4px;
      }
    }
  }
}
.ant-dropdown-menu {
  padding: 15px 0;
  .ant-dropdown-menu-item {
    .ant-dropdown-menu-title-content {
      i {
        margin-right: 12px;
      }
    }
  }
}
</style>
