<template>
  <a-card>
    <template v-slot:title>
      <div>Change Password</div>
      <div class="card-subtitle">Manage your account</div>
    </template>
    <div class="editor-container">
      <p class="editor-head">Old Password</p>
      <a-input-password
        v-model:value="oldPasswordInput"
        @blur="validateOldPass($event)"
      />
      <p
        v-if="!validOldPass"
        class="invalid-notice"
      >
        {{ warningOldPass }}
      </p>
      <p class="editor-head">New Password</p>
      <a-input-password
        v-model:value="newPasswordInput"
        @blur="requireNewPass($event)"
        @keyup="validateNewPass($event)"
      />
      <p
        v-if="!validNewPass"
        class="invalid-notice"
      >
        The new password is required.
      </p>
      <div class="pass-criteria">
        <p :class="{ active: activeCriteria, valid: validNewPassCombination }">
          <FeatherIcon
            type="circle"
            :size="10"
          />Must contain at least one lowercase letter, one uppercase letter,
          and one number
        </p>
        <p :class="{ active: activeCriteria, valid: validNewPassLength }">
          <FeatherIcon
            type="circle"
            :size="10"
          />Must be at least 8 characters long
        </p>
        <p :class="{ active: activeCriteria, valid: validNewPassChar }">
          <FeatherIcon
            type="circle"
            :size="10"
          />Allowed special characters: ! @ # $ % ^ & * ( )
        </p>
      </div>
      <p class="editor-head">Confirm New Password</p>
      <a-input-password
        v-model:value="confirmPasswordInput"
        @blur="validateConfirmPass($event)"
      />
      <p
        v-if="!validConfirmPass"
        class="invalid-notice"
      >
        {{ warningConfirmPass }}
      </p>
      <a-button
        type="primary"
        @click="onChangePassword"
        >Change Password</a-button
      >
    </div>
  </a-card>
</template>

<script>
import { ref } from 'vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';

const securePassRegExp = new RegExp('((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*)+$');

const ChangePassword = {
  name: 'ChangePassword',
  setup() {
    let validOldPass = ref(true);
    let validNewPass = ref(true);
    let validConfirmPass = ref(true);
    let warningOldPass = ref('The old password is required.');
    let warningConfirmPass = ref('The confirm password is required.');
    let activeCriteria = ref(false);
    let validNewPassLength = ref(false);
    let validNewPassCombination = ref(false);
    let validNewPassChar = ref(true);

    let oldPasswordInput = ref('');
    let newPasswordInput = ref('');
    let confirmPasswordInput = ref('');

    const validateOldPass = (e) => {
      if (e.target.value) {
        validOldPass.value = true;
      } else {
        validOldPass.value = false;
        warningOldPass.value = 'The old password is required.';
      }
    };

    const requireNewPass = (e) => {
      if (e.target.value) {
        validNewPass.value = true;
      } else {
        validNewPass.value = false;
      }
    };

    const validateNewPass = (e) => {
      activeCriteria.value = true;
      if (e.target.value.length < 8) {
        validNewPassLength.value = false;
      } else {
        validNewPassLength.value = true;
      }
      if (securePassRegExp.test(e.target.value)) {
        validNewPassCombination.value = true;
      } else {
        validNewPassCombination.value = false;
      }
      // hardcode symbol list '='
      // use regex with full list later
      if (e.target.value.includes('=')) {
        validNewPassChar.value = false;
      } else {
        validNewPassChar.value = true;
      }
    };

    const validateConfirmPass = (e) => {
      if (e.target.value) {
        validConfirmPass.value = true;
      } else {
        validConfirmPass.value = false;
        warningConfirmPass.value = 'The confirm password is required.';
      }
      if (e.target.value !== newPasswordInput.value) {
        validConfirmPass.value = false;
        warningConfirmPass.value =
          'The password and confirm password do not match';
      }
    };

    const onChangePassword = async () => {
      console.log('change pass');
      if (validOldPass.value && validNewPass.value && validConfirmPass.value) {
        await api
          .changeUserPassword({
            'old-password': oldPasswordInput.value,
            'new-password': newPasswordInput.value,
          })
          .then((response) => {
            if (response.status === 'OK') {
              notification.success({
                message: 'Password Changed',
                description: 'Your password has been changed successfully',
              });
              oldPasswordInput.value = '';
              newPasswordInput.value = '';
              confirmPasswordInput.value = '';
              validConfirmPass.value = true;
            }
          })
          .catch((e) => {
            const m = api.extractErrorMessage(e);
            if (m === 'Old password is invalid.') {
              validOldPass.value = false;
              warningOldPass.value =
                'Incorrect current password. Please double-check and try again';
            }
            notification.error({
              message: 'Incorrect Password',
              description:
                'Incorrect current password. Please double-check and try again',
            });
          });
      }
    };

    return {
      validOldPass,
      validNewPass,
      validConfirmPass,
      warningOldPass,
      warningConfirmPass,
      activeCriteria,
      validNewPassLength,
      validNewPassCombination,
      validNewPassChar,
      validateOldPass,
      validateNewPass,
      validateConfirmPass,
      requireNewPass,
      oldPasswordInput,
      newPasswordInput,
      confirmPasswordInput,
      onChangePassword,
    };
  },
};
export default ChangePassword;
</script>

<style lang="scss">
.pass-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    border: none;
    padding-left: 64px;
  }
  .ant-modal-body {
    border: none;
    padding-left: 64px;
    padding-top: 0;
    position: relative;
    .floating-title-icon {
      position: absolute;
      left: 35px;
      top: -33px;
      color: #20c977;
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      .ant-btn:first-child {
        display: none;
      }
      .ant-btn-primary {
        background: #20c997;
        padding: 12px 32px;
      }
    }
  }
}
</style>
